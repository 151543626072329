import { request } from "@/utils/request";

/**
 *
 * @returns {电子合同账单核销}
 *
 */
//  获取已申请核销账单列表
export function getRequestedRepaymentPlan(data, pagenum, size) {
    return request({
        url: `/api/manage/rePaymentPlan/getRequestedRepaymentPlan/${pagenum}/${size}`,
        method: "post",
        data
    });
}

//  获取已通过核销账单列表
export function getAuditedRepaymentPlan(data, pagenum, size) {
    return request({
        url: `/api/manage/rePaymentPlan/getAuditedRepaymentPlan/${pagenum}/${size}`,
        method: "post",
        data
    });
}

//  申请核销
export function requestWriteoff(id) {
    return request({
        url: `/api/manage/rePaymentPlan/requestWriteoff/${id}`,
        method: "post"
    });
}


//  核销通过
export function writeoffPass(id) {
    return request({
        url: `/api/manage/rePaymentPlan/writeoffPass/${id}`,
        method: "post"
    });
}


//  核销不通过
export function writeoffUnPass(id) {
    return request({
        url: `/api/manage/rePaymentPlan/writeoffUnPass/${id}`,
        method: "post"
    });
}

//  核销--撤销
export function revokeWriteoff(id) {
    return request({
        url: `/api/manage/rePaymentPlan/revokeWriteoff/${id}`,
        method: "post"
    });
}