<template>
  <div class='provePayment'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">核销管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div>
        <el-tabs v-model="activeProperty" type="card">
          <el-tab-pane label="待核销账单" name="1">
            <ProvePayTable v-if="activeProperty == 1" :proveType="activeProperty" :adminAuthority="AdminAuthority" :isAuditor="isAuditor" />
          </el-tab-pane>
          <el-tab-pane label="已核销账单" name="2">
            <ProvePayTable v-if="activeProperty == 2" :proveType="activeProperty" :adminAuthority="AdminAuthority" :isAuditor="isAuditor" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
    
<script>
import ProvePayTable from "./commons/provePayTable.vue";

export default {
  name: "provePayment",
  components: {
    ProvePayTable,
  },
  data() {
    return {
      activeProperty: "1", // 1待处理 2已处理
      AdminAuthority: false, //是否是管理员
      isAuditor: false, //财务人员(核销管理权限)
    };
  },
  mounted() {
    this.AdminAuthority = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo.hasAdmin;
    this.isAuditor = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo.hasCwAdmin ?? false;
  },
  methods: {},
};
</script>
    
<style lang='less' scoped>
.provePayment {
  padding: 60px 20px 20px 20px;
}
</style>